/**
 * Custom jQuery plugin to toggle display of search bar field
 * Expects standard WP search form with classes for .search-field and .search-submit
 *
 * When search field is empty, submit button toggles display of search field.
 * Otherwise, button submits normally. Field auto hides after a second if it
 * loses focus.
 *
 * @since	3/26/14
 * @author	Chatman Design / Corey Caswick
 * @link		http://chatmandesign.com
 */

( function( $ ) {
	$.fn.cdSearchSlideout = function() {
		return this.each(function(){

			var $s = $(this);
			var $field = $s.find('.search-field');
			var $button = $s.find('.search-submit');

			$button.click(function(e){
				if ( !$s.hasClass('open') ) {
					e.preventDefault();
					$s.addClass('open');
				}
				else if ( !$field.val() ) {
					e.preventDefault();
					$s.removeClass('open');
				}
			});
			$field.blur(function(){
				// Auto hide if field loses focus
				window.setTimeout(function(){
					if ( ( !$button.is(':focus') ) && ( !$field.is(':focus') ) ) {
						$s.removeClass('open');
					}
				}, 1000);
			});

		});
	}
} )( jQuery );
